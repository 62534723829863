import React, { useEffect, useState, createContext, useContext } from 'react';
import axios from 'axios';
import './History.css';

export const AccordionContext = createContext({
  active: 0,
  setActive: () => {}
});

const AccordionWrapper = (props) => {
  const [active, setActive] = useState(null);

  return (
    <AccordionContext.Provider value={{ active, setActive }}>
      <div className="accordion-wrapper">
        <form>{props.children}</form>
      </div>
    </AccordionContext.Provider>
  );
};

const AccordionItem = (props) => {
  const { active, setActive } = useContext(AccordionContext);

  const eventHandler = (e, index) => {
    e.preventDefault();
    setActive(active === index ? null : index);
  };

  return (
    <div className="accordion-item">
      <h3 className="accordion-title">
        <button
          onClick={(e) => eventHandler(e, props.index)}
          className={active === props?.index ? 'active' : 'inactive'}
          aria-expanded={active === props?.index ? 'true' : 'false'}
          aria-controls={`sect-${props?.index}`}
          aria-disabled={active === props?.index ? 'true' : 'false'}
        >
          <span className="title-wrapper">
            {props?.answer?.length > 50 ? `${props?.answer?.substring(0, 50)}...` : props.answer}
          </span>
          <span className="time-wrapper">Date and Time: {new Date(props?.time).toLocaleString()}</span>
          <span className="icon-wrapper">
            <span className={active === props.index ? 'minus' : 'plus'}></span>
          </span>
        </button>
      </h3>

      <div className="accordion-panel">
        <div id={`sect-${props.index}`} className={active === props.index ? 'panel-open' : 'panel-close'}>
          {props.description.map((contentItem, contentIndex) => (
            <div key={contentItem?.answer[0]?.question_id}>
              {contentItem?.solutions?.map((solution) => (
                <div key={solution.id}>
                  <h4>Category Name: {solution?.category_name}</h4>
                  {contentItem.answer.map((answerItem, answerIndex) => (
                    <div key={answerItem.question_id}>
                      <h4>
                        Prompt {answerIndex + 1}: {answerItem?.question_text}
                      </h4>
                      <p
                        className="response-container"
                        dangerouslySetInnerHTML={{ __html: `Response ${answerIndex + 1}: ${answerItem.answer}` }}
                      ></p>
                    </div>
                  ))}
                  <h5>Solution:</h5>
                  <div dangerouslySetInnerHTML={{ __html: solution?.solution }}></div>
                  {contentIndex !== (props?.description?.length ?? 0) - 1 && <hr />}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

function History() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('JWT');

    if (token) {
      axios
        .get(`${process.env.REACT_APP_URL}/history`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((response) => {
          console.log('JWT token:', token);
          console.log('API Response:', response?.data);

          // Assuming the API response is an array of historical data objects
          const historyData = response?.data?.history;

          // Sort the data by 'time' in descending order (most recent first)
          historyData.sort((a, b) => new Date(b.time) - new Date(a.time));

          // Filter and keep the most recent 5 entries
          const recentData = historyData.slice(0, 5);

          setCategories(recentData);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    } else {
      console.error('JWT token not found in local storage');
    }
  }, []);

  return (
    <div className="history-container">
      <h3 className="landing-page-title">Select A History Section To Begin</h3>

      <div className="App01">
        <div className="content01">
          <div className="app-description">
            <p>
              Choose the section using the Tab key. Press Enter to open. Shift + Tab to go to select the previous
              section.
            </p>
          </div>

          <AccordionWrapper>
            {categories.length > 0 ? (
              categories.map((item, index) => (
                <AccordionItem
                  key={index}
                  index={index}
                  answer={item?.content[0]?.answer[0]?.answer}
                  description={item?.content}
                  time={item?.time}
                ></AccordionItem>
              ))
            ) : (
              <h3 className="no_response">No History Available.</h3>
            )}
          </AccordionWrapper>
        </div>
      </div>
    </div>
  );
}

export default History;
