import React from 'react';
import { Bars3CenterLeftIcon } from '@heroicons/react/20/solid';
import Button from 'react-bootstrap/Button';

import './Question.css';

const QuestionHeader = ({ category, question, questions, questionKeys, toggleSlideOver, goToQuestion }) => (
  <div>
    <div className="question-container-name">
      <h3>{category.name}</h3>
      <Button className="question-container-name-preview" style={{ marginLeft: '10px' }} onClick={toggleSlideOver}>
        <Bars3CenterLeftIcon className="question-container-name-icon" />
        <div>Preview</div>
      </Button>
    </div>
    <div className="question-pagination">
      {questionKeys.map((questionNumber) => {
        const answer = questions.find((item) => item.position.toString() === questionNumber.toString())?.answer;
        const isAnswered = !!answer; // Check if the question is answered
        const isActive = questionNumber.toString() === question.position.toString();
        const buttonClass = isAnswered ? 'answered-button' : 'unanswered-button';
        const currentQuestion = questions.sort((a, b) => a.position - b.position)[questionNumber - 1];

        return (
          <div
            key={currentQuestion.id}
            onClick={() => goToQuestion(currentQuestion.id)}
            className={`${buttonClass} ${isActive ? 'active-button' : ''}`}
          >
            {questionNumber}
          </div>
        );
      })}
    </div>
    <div className="question-container-counter">
      <div className="question-container-counter-current">{question.position}</div>
      <div className="question-container-counter-total">&nbsp;{`/ ${questionKeys.length}`}</div>
    </div>
  </div>
);

export default QuestionHeader;
