import React from 'react';
import { PencilSquareIcon } from '@heroicons/react/20/solid';

const AnswerItem = ({ index, question, id, onOpenQuestion }) => (
  <div className="answer-item">
    <div className="answer-item-name">{`${index + 1}. ${question.question}`}</div>
    <div className="answer-item-problem">{question.answer || 'Not Set'}</div>
    <div className="answer-item-edit" onClick={() => onOpenQuestion({ id })}>
      <PencilSquareIcon className="answer-item-icon" />
      <div className="answer-item-icon-text">Edit</div>
    </div>
  </div>
);

export default AnswerItem;
