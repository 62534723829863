export const removeHtmlTags = (content) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content || '', 'text/html');

  return doc.body.textContent || '';
};

export const removeDocType = (content) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');

  if (doc.doctype) {
    doc.removeChild(doc.doctype);
  }

  return doc.documentElement.outerHTML || '';
};
