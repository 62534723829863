import React from 'react';
import Form from 'react-bootstrap/Form';
import { XMarkIcon } from '@heroicons/react/20/solid';

import './Question.css';

const QuestionForm = ({ question, textareaRef, answer, rowsSize, onClearAnswer, onInputChange }) => (
  <Form>
    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
      <Form.Label className="question-container-input-label">{question}</Form.Label>
      {answer && <XMarkIcon className="x-mark-icon" onClick={onClearAnswer} />}
      <Form.Control
        ref={textareaRef}
        className="question-container-input-answer"
        as="textarea"
        rows={rowsSize}
        placeholder="Type your answer here"
        onChange={onInputChange}
        value={answer}
      />
    </Form.Group>
  </Form>
);

export default QuestionForm;
