/* eslint max-len: 0 */

import React from 'react';

import './Icon.css';

const ArrowSmallRight = () => (
  <svg
    className="icon-small-right"
    width="24"
    height="24"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 12L19.5 12M19.5 12L12.75 5.25M19.5 12L12.75 18.75"
      stroke="#1F2937"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ArrowSmallRight;
