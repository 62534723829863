import { makeAutoObservable } from 'mobx';
import authStore from './authStore';
import { categoriesExtentions } from '../constants/categories';
import categoryKeyFor from '../components/Helpers/CategoryHelper';
import useChatBotContext from '../hooks/useChatBotContext';

const API_ENDPOINT = 'https://cirrusinsight-ai-be.onrender.com/categories';

const toDashLowerCase = (str) =>
  str
    .trim() // Remove any leading or trailing spaces
    .toLowerCase() // Convert string to lowercase
    .replace(/\s+/g, '-'); // Replace spaces with dashes

const decorateQuestions = (questions) => {
  const sortedQuestions = questions
    .map((question, index) => ({
      id: question.id,
      position: index + 1,
      question: question.text,
      answer: question.answer || ''
    }))
    .sort((a, b) => a.position - b.position);

  return sortedQuestions;
};

const decorateCategories = (originalCategories) =>
  originalCategories.reduce((acc, category) => {
    const key = toDashLowerCase(category.name);

    acc[key] = {
      id: category.id,
      solutionQuestion: categoriesExtentions[key]?.solutionQuestion,
      chains: categoriesExtentions[key],
      position: category.position,
      key,
      chatKey: categoryKeyFor({ ...category, key }),
      firstQuestionId: category.questions[0].id,
      name: category.name,
      questions: decorateQuestions(category.questions),
      solution: category.solution[0]?.solution
    };

    return acc;
  }, {});

class CategoryStore {
  categories = {};

  loading = false;

  error = null;

  constructor() {
    makeAutoObservable(this);
  }

  updateSolution = async (categoryId, newSolution) => {
    const jwtToken = authStore.token();
    this.loading = true;
    try {
      const response = await fetch(`https://cirrusinsight-ai-be.onrender.com/categories/${categoryId}/solution`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtToken}`
        },
        body: JSON.stringify({
          solution: newSolution
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      await this.fetchCategories(); // TODO: Update solution without fetching categories
    } catch (error) {
      console.error('Error updating the answer:', error);
    } finally {
      this.loading = false;
    }
  };

  updateAnswer = async (questionId, newAnswer) => {
    const jwtToken = authStore.token();

    this.loading = true;
    try {
      const response = await fetch(`https://cirrusinsight-ai-be.onrender.com/questions/${questionId}/answer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtToken}`
        },
        body: JSON.stringify({
          answer: newAnswer
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      await this.fetchCategories(); // TODO: Update answer without fetching categories
    } catch (error) {
      console.error('Error updating the answer:', error);
    } finally {
      this.loading = false;
    }
  };

  fetchCategories = async () => {
    this.loading = true;
    this.error = null;

    const jwtToken = authStore.token(); // Retrieve JWT token from local storage

    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtToken}` // Add JWT token to the request headers
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const dataArray = Object.entries(decorateCategories(data.categories));
      dataArray.sort((a, b) => a[1].position - b[1].position);

      this.categories = Object.fromEntries(dataArray);

      const { writeContext } = useChatBotContext();

      // Rewrite the context history for ChatGPT
      Object.keys(this.categories).forEach((categoryKey) => {
        this.categories[categoryKey].questions.forEach((question) => {
          writeContext({
            content: `${question.question} ${question.answer || 'not sure'}`,
            key: `${this.categories[categoryKey].position}_${this.categories[categoryKey].key}_${question.position}`
          });
        });
      });
    } catch (error) {
      console.log(error);
      if (error.message) {
        authStore.logout(); // Please send an appropriate API response to hande unauthenticated requests
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location = '/';
      }
      // this.error = error.message;
    } finally {
      this.loading = false;
    }
  };

  getCategories() {
    // Check if categories are empty. If they are, fetch them.
    if (Object.keys(this.categories).length === 0) {
      this.fetchCategories();
    }
    return this.categories;
  }

  setCategories(value) {
    this.categories = value;
  }
}

const categoryStore = new CategoryStore();
export default categoryStore;
