import React from 'react';
import AnswerItem from './AnswerItem';

import './Answer.css';

const Answer = ({ category, questions, handleOpenQuestion }) => (
  <div className="answer-container">
    <h3 className="answer-container-title">{category.name}</h3>
    {questions
      // .sort((question) => question.position)
      .map((question) => (
        <AnswerItem
          onOpenQuestion={handleOpenQuestion}
          index={question.position - 1}
          question={question}
          id={question.id}
          key={question.id}
        />
      ))}
  </div>
);

export default Answer;
