import React, { useEffect } from 'react';

const useKeyboardNavigation = (ref, { onLeftArrow, onRightArrow, onEnter }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (ref.current) {
        if (document.activeElement === ref.current) {
          // Check if the ref is focused
          if (event.key === 'Enter') {
            if (onEnter) {
              onEnter();
            }
          }
        } else {
          switch (event.key) {
            case 'ArrowLeft':
              if (onLeftArrow) {
                onLeftArrow();
              }
              break;
            case 'ArrowRight':
              if (onRightArrow) {
                onRightArrow();
              }
              break;
            default:
              break;
          }
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [ref, onLeftArrow, onRightArrow, onEnter]);
};

export default useKeyboardNavigation;
