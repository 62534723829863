/* eslint max-len: 0 */

import React from 'react';

import './Icon.css';

const ArrowLeftOnSquareIcon = () => (
  <svg
    className="arrow-left-on-square-icon"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.75 8.22803L4.25 8.22803M4.25 8.22803L7.625 11.603M4.25 8.22803L7.625 4.85303"
      stroke="#9CA3AF"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect x="0.5" y="0.728027" width="15" height="15" rx="3.5" stroke="#9CA3AF" />
  </svg>
);

export default ArrowLeftOnSquareIcon;
