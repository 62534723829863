/* eslint-disable no-unused-expressions */
import { loadPersistedData, persistData } from '../components/Helpers/PersistData';

const FULL_HISTORY_CONTEXT_KEY = 'chat-gpt-full-context';

// We keep each Chat message in local storage, they may be replaced but not duplicated, each of them connected to question/answer
// if we want to add new one to the end of queue we need to add it to the end of array but this will change the strategy
// The structure:
// {"$category.position-$category.key-$question_id_or_answer" => {role: "user", content: "question"}}

const DEFAULT_CONTEXT_MESSAGES = {
  '0_a_default_user_intro': {
    role: 'user',
    content: 'please use the next my prompts as a context, and never use preamble'
  },
  '0_b_default_user_intro': {
    role: 'assistant',
    content:
      "I'd be happy to use your prompts as context for our conversation. Please provide your prompts, and I'll respond accordingly.\n" // Exactly what ChatGPT says
  }
};
const useChatBotContext = () => {
  const writeContext = ({ content, role = 'user', key }) => {
    const history = loadPersistedData(FULL_HISTORY_CONTEXT_KEY) || DEFAULT_CONTEXT_MESSAGES;

    history[key] = { role, content };

    persistData(history, FULL_HISTORY_CONTEXT_KEY);
  };

  const contextHistory = () => loadPersistedData(FULL_HISTORY_CONTEXT_KEY) || {};

  return { writeContext, contextHistory };
};

export default useChatBotContext;
