import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import './Question.css';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import ArrowLeftOnSquareIcon from '../Shared/Icons/ArrowLeftOnSquareIcon';
import logoAnimation from './logoAnimation.gif';

import ArrowBackOnSquareIcon from '../Shared/Icons/ArrowBackOnSquareIcon';
import ArrowRightOnSquareIcon from '../Shared/Icons/ArrowRightOnSquareIcon';
import { purgePersistedData } from '../Helpers/PersistData';

const QuestionHeader = ({
  isAnswering,
  onStopAnswering,
  onStartAnswering,
  onPreviousQuestion,
  nextQuestionId,
  onNextQuestion
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen1, setModalOpen1] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleModalOpen1 = () => {
    setModalOpen1(true);
  };

  const handleModalClose1 = () => {
    setModalOpen1(false);
  };

  const handleCloseSession = async () => {
    const token = localStorage.getItem('JWT');
    try {
      // Make the API call to close the session
      const response = await axios.post(`${process.env.REACT_APP_URL}/end_session`, null, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // Check the response status and handle accordingly
      if (response.status === 200) {
        // Session closed successfully, remove the item from local storage
        localStorage.removeItem('chat-gpt-full-context');
        console.log('Session closed successfully');

        // Show a success toast
        toast.success('Data saved successfully', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
          onClose: () => {
            // This callback will be called when the toast message is closed

            // Reload the page here
            window.location.reload();
          }
        });
      } else {
        // Handle any error or failure cases here
        console.error('Failed to close session:', response.statusText);

        // Show an error toast
        toast.error('Something went wrong', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } catch (error) {
      // Handle Axios error
      console.error('An error occurred while closing the session:', error);

      // Show an error toast
      toast.error('Please enter all the data, Please generate solution.', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  const deleteContext = async () => {
    const token = localStorage.getItem('JWT');
    try {
      const response = await axios.delete(`${process.env.REACT_APP_URL}/delete_data`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.status === 200) {
        localStorage.removeItem('chat-gpt-full-context');
        console.log('Context Cleared successfully');
        toast.success('Context Cleared successfully', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
          onClose: () => {
            window.location.reload();
          }
        });
      } else {
        console.error('Failed to clear session:', response.statusText);
        toast.error('Something went wrong', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } catch (error) {
      console.error('An error occurred while closing the session:', error);
      toast.error('Something went wrong', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  const clearData = () => {
    // Call deleteContext when the "Ok" button is clicked in the modal
    deleteContext();
    // Close the modal
    handleModalClose();
  };

  const dontClearData = () => {
    // Close the modal when the "Cancel" button is clicked
    handleModalClose();
  };

  const clearData1 = () => {
    // Call deleteContext when the "Ok" button is clicked in the modal
    handleCloseSession();
    // Close the modal
    handleModalClose1();
  };

  const dontClearData1 = () => {
    // Close the modal when the "Cancel" button is clicked
    handleModalClose1();
  };

  return (
    <div>
      <div className="question-container-actions">
        <Button className="question-container-actions-previous" onClick={onPreviousQuestion}>
          <div className="question-container-actions-button-text">Previous</div>

          <ArrowLeftOnSquareIcon />
        </Button>

        <Button
          className="question-container-actions-generate"
          onClick={isAnswering ? onStopAnswering : onStartAnswering}
        >
          {isAnswering ? (
            'Stop Answering'
          ) : (
            <>
              <img src={logoAnimation} className="question-container-actions-logo" alt="logo animation" />

              <span>Help with answer</span>
            </>
          )}
        </Button>

        {nextQuestionId === 'result' ? (
          <Button className="question-container-actions-next" style={{ float: 'right' }} onClick={onNextQuestion}>
            <div className="question-container-actions-button-text">Review</div>

            <ArrowBackOnSquareIcon />
          </Button>
        ) : (
          <Button className="question-container-actions-next" style={{ float: 'right' }} onClick={onNextQuestion}>
            <div className="question-container-actions-button-text">Next</div>

            <ArrowRightOnSquareIcon />

            {/* <ArrowBackOnSquareIcon /> */}
          </Button>
        )}
      </div>
      <Button
        variant="primary"
        style={{ opacity: '0.5', marginTop: '100px', marginLeft: '312px' }}
        // onClick={handleCloseSession}
        onClick={handleModalOpen1}
      >
        Save Session
      </Button>
      {modalOpen1 && (
        <div className="modal-background-overlay">
          <div className="modalEditUserProfile">
            <div className="modal-content-1UserProfile">
              <p className='para_data'>Please confirm to save the data, you have entered.</p>
              <div className="modal-buttonsUserProfile">
                <button className="modal-ok-buttonUserProfile" onClick={clearData1}>
                  Ok
                </button>
                <button className="modal-cancel-buttonUserProfile" onClick={dontClearData1}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />

      <Button
        variant="primary"
        style={{ opacity: '0.5', marginTop: '-65px', marginLeft: '670px' }}
        onClick={handleModalOpen}
      >
        Cleanup Context
      </Button>

      {/* Add the modal background overlay and modal */}
      {modalOpen && (
        <div className="modal-background-overlay">
          <div className="modalEditUserProfile">
            <div className="modal-content-1UserProfile">
              <p className='para_data'>Please confirm to erase all the data you have entered.</p>
              <div className="modal-buttonsUserProfile">
                <button className="modal-ok-buttonUserProfile" onClick={clearData}>
                  Ok
                </button>
                <button className="modal-cancel-buttonUserProfile" onClick={dontClearData}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionHeader;
