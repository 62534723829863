import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import logoName from './logo-name.png';
import logoIcon from './logo-icon.png';
import authStore from '../../stores/authStore';
import './Login.css';

const Login = () => {
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();

  const [code, setCode] = useState();
  const [renderContent, setRenderContent] = useState(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const codeTemp = urlSearchParams.get('code');
    setCode(codeTemp);

    console.log('codeTemp:', codeTemp);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      setRenderContent(true);
      const username = user?.email;
      const url = process.env.REACT_APP_URL;
      Axios.post(`${url}/loginRegister`, { username })
        .then((response) => {
          const token = response.data.access_token;
          authStore.login(username, token);
          localStorage.setItem('JWT', token);
          localStorage.setItem('currentUser', username);
          navigate('/home');
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isAuthenticated, user, navigate]);

  return (
    <>
      {isAuthenticated ? (
        <p className="loadingCenter">Loading...</p>
      ) : (
        <>
          <div>
            <div className="context">
              <div className="public-layout">
                <div className="center-container">
                  <div className="landing-page-logo-container">
                    <img className="landing-page-icon-logo" src={logoIcon} alt="Your Logo" />
                    <img className="landing-page-name-logo" src={logoName} alt="Your Logo" />
                  </div>
                  <div>
                    <p className="welcomeText">
                      Empowering Your AI Journey At CirrusInsightsNow.AI, We are dedicated to transforming your vision
                      into reality.
                      <br />
                      Our cutting-edge Artificial Intelligence solutions are designed to forward, propel your business
                      harnessing
                      <br />
                      the power of AI to drive innovation, boost efficiency, and unlock new possibilities.
                    </p>
                  </div>
                  <div className="grid-container">
                    <div className="grid-item">
                      <h1>Do you need Solution for your Problem Statement!</h1>
                      <p>
                        Indeed, Our AI-based model services are committed to offering solutions that are specifically
                        suited to your problem statement. Using state-of-the-art AI technology, we are fully prepared to
                        help you find the perfect AI-driven solution for your particular needs.
                      </p>
                    </div>
                    <div className="grid-item">
                      <h1>Unlock Data Insights: Your Smart Analytics Companion</h1>
                      <p>
                        Our analytics platform is meticulously crafted to empower you with the ability to extract
                        invaluable insights from your data. By leveraging our intelligent analytics tools, you can
                        effortlessly generate precise and informative user stories. These user stories are not only
                        accurate but also rich in detail, enabling you to make well-informed decisions and gain a deeper
                        understanding of your data's narrative.
                      </p>
                    </div>
                    <div className="grid-item">
                      <h1>Data-Driven Decisions Made Simple with Our AI Analytics Tool</h1>
                      <p>
                        Our AI-powered analytics tool simplifies the process of making data-driven decisions. It's
                        user-friendly and provides actionable insights to drive your business forward.
                      </p>
                    </div>
                  </div>

                  <button className="animated-button" onClick={() => loginWithRedirect()}>
                    <span className="button-text">Get Started</span>
                    <span className="arrow-icon">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div className="area">
              <ul className="circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Login;
