import React from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { gherkin } from 'react-syntax-highlighter/dist/esm/languages/hljs';
import { dark } from 'react-syntax-highlighter/dist/cjs/styles/hljs';

SyntaxHighlighter.registerLanguage('gherkin', gherkin);

const formatGherkinCode = (code) =>
  // Replace spaces between "Given," "When," and "Then" steps with line breaks
  code?.replace(/(Given|When|Then) /g, '\n    $1 ')?.replace(/(And|But) /g, '\n      $1 ');

const GherkinSyntax = ({ content, className }) => {
  const codeBlockRegex = /<cucumber>([\s\S]*?)<\/cucumber>/g;
  const codeBlockMatches = content.match(codeBlockRegex);

  // ChatGPT not always provides Gherkin code in a code block so this is kid of hack for it
  if (!codeBlockMatches && content.match(/(java\s*code|gherkin|Scenario:.*Given.*When.*Then)/i)) {
    return (
      <SyntaxHighlighter className={className} language="gherkin" style={dark}>
        {formatGherkinCode(content)?.replace(/```/g, '')}
      </SyntaxHighlighter>
    );
  }

  if (!codeBlockMatches) {
    return <div className={className} dangerouslySetInnerHTML={{ __html: content }}></div>;
  }

  // Split the content into sections based on code blocks
  const sections = content.split(codeBlockRegex).filter((section) => section?.length > 0);

  return sections.map((section, index) => {
    if (index % 2 === 0) {
      // if (index % 2 === 0 || (!section.includes('<pre') && !section.includes('<code'))) {
      // Render HTML content as HTML
      return <div key={index} dangerouslySetInnerHTML={{ __html: section }}></div>;
    }
    // Extract the Gherkin code and render it with syntax highlighting
    const gherkinCode = codeBlockMatches[index - 1]; // Get the Gherkin code from the matches
    const htmlContent = gherkinCode?.replace(/<\/?pre>/g, '')?.replace(/<\/?code>/g, '');
    const formattedGherkinCode = formatGherkinCode(htmlContent);

    return (
      <React.Fragment key={index}>
        {/* <div className={className} dangerouslySetInnerHTML={{ __html: section }}></div> */}
        <SyntaxHighlighter className={className} language="gherkin" style={dark}>
          {formattedGherkinCode}
        </SyntaxHighlighter>
      </React.Fragment>
    );
  });
};
export default GherkinSyntax;
