import { makeAutoObservable } from 'mobx';

class AuthStore {
  isLoggedIn = false;

  constructor() {
    makeAutoObservable(this);
  }

  isAuthenticated() {
    return localStorage.getItem('isAuthenticated') === 'true';
  }

  token() {
    return localStorage.getItem('JWT');
  }

  user() {
    return localStorage.getItem('currentUser');
  }

  login(user, token) {
    this.isLoggedIn = true;
    localStorage.setItem('isAuthenticated', true);
    localStorage.setItem('JWT', token);
    localStorage.setItem('currentUser', user);
  }

  logout() {
    this.isLoggedIn = false;
    localStorage.clear();
  }

  // ... other authentication-related methods ...
}

const authStore = new AuthStore();
export default authStore;
