import React from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import Overlay from './Overlay';

import './SlideOver.css';

function SlideOver({ isOpen, onClose, children }) {
  const slideOverClass = isOpen ? 'slide-over open' : 'slide-over';

  return (
    <div className={slideOverClass}>
      <Overlay isOpen={isOpen} onClose={onClose} />
      <div className="slide-over-content">
        <XMarkIcon className="x-mark-icon" onClick={onClose} />
        {children}
      </div>
    </div>
  );
}

export default SlideOver;
