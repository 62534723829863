/* eslint max-len: 0 */

import React from 'react';

import './Icon.css';

const ArrowBackOnSquareIcon = () => (
  <svg
    className="arrow-back-on-square-icon"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4.22803C12 3.95188 11.7761 3.72803 11.5 3.72803C11.2239 3.72803 11 3.95188 11 4.22803H12ZM11.5 9.00012V9.50012H12V9.00012H11.5ZM11 4.22803V9.00012H12V4.22803H11ZM11.5 8.50012H4.5V9.50012H11.5V8.50012Z"
      fill="#9CA3AF"
    />
    <path d="M7 6.5L4.5 9L7 11.5" stroke="#9CA3AF" stroke-linecap="round" />
    <rect x="0.5" y="0.728027" width="15" height="15" rx="3.5" stroke="#9CA3AF" />
  </svg>
);

export default ArrowBackOnSquareIcon;
