/* eslint-disable no-unused-expressions */
import { useEffect, useRef, useState } from 'react';
import { SSE } from 'sse';
import { ChatOpenAI } from 'langchain/chat_models/openai';
import { LLMChain } from 'langchain/chains';
import { ChatPromptTemplate } from 'langchain/prompts';
import useChatBotContext from './useChatBotContext';
import { chainsList, chainsNames } from '../constants/categories';

const CHAT_BOT_ENDPOINT = 'https://api.openai.com/v1/chat/completions';
const CHAT_GPT_SETTINGS = {
  model: 'gpt-3.5-turbo-16k-0613',
  temperature: 0.5,
  top_p: 0.5,
  max_tokens: 15000,
  stream: true,
  frequency_penalty: 1,
  presence_penalty: 1,
  n: 1
};

const useChatBot = ({ callback, callbackOnFinish, messages, category, withAnswerMemo = true }) => {
  const [answer, setAnswer] = useState('');
  const [isAnswering, setIsAnswering] = useState(false);
  const [isCompleted, setIsCompleted] = useState(true);
  const { writeContext } = useChatBotContext();
  const sourceRef = useRef({ source: {} });
  const answerRef = useRef();
  const handleAnswerRef = (text) => setAnswer(text);

  useEffect(() => {
    answerRef.current = answer;
    if (withAnswerMemo && answerRef.current.length > 0) {
      writeContext({
        role: 'assistant',
        content: answerRef.current,
        key: `${category.position}_${category.key}_c_answer`
      });
    }
  }, [answer, category, withAnswerMemo, writeContext]);

  const handleStartAnsweringTemplates = async ({ chainName, keepChain }) => {
    if (messages !== '') {
      setIsAnswering(true);
      setAnswer(keepChain || '');
      answerRef.current = keepChain || '';

      setIsCompleted(false);
      const chat = new ChatOpenAI({
        openAIApiKey: process.env.REACT_APP_GPT_API_KEY,
        modelName: 'gpt-3.5-turbo-16k-0613',
        temperature: 0.5,
        topP: 0.5,
        maxTokens: 15000,
        n: 1,
        streaming: true
      });

      const parsedMessages = keepChain
        ? Object.values(messages.pop()).map((item) => item.slice(-1000))
        : messages.map((item) => Object.values(item).map((i) => i.slice(-1000))).slice();
      const messagesPrompt = ChatPromptTemplate.fromMessages(parsedMessages.concat([chainsList[chainName]]));

      const chain = new LLMChain({
        llm: chat,
        prompt: messagesPrompt
      });

      await chain.call({
        statement: chainsNames[chainName].solutionQuestion || category.solutionQuestion,
        callbacks: [
          {
            handleLLMNewToken(token) {
              answerRef.current += token;
              handleAnswerRef(answerRef.current);

              if (callback) {
                callback({ answer: answerRef.current });
              }
            }
          }
        ]
      });

      if (callbackOnFinish) {
        callbackOnFinish({ answer: answerRef.current });
      }
      setIsAnswering(false);
      setIsCompleted(true);
    }
  };

  const handleStartAnsweringChat = async ({ chainName }) => {
    if (messages !== '') {
      setIsAnswering(true);
      setAnswer('');
      const data = { ...CHAT_GPT_SETTINGS, messages };

      setIsCompleted(false);

      sourceRef.source = new SSE(CHAT_BOT_ENDPOINT, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_GPT_API_KEY}`
        },
        method: 'POST',
        payload: JSON.stringify(data)
      });

      sourceRef.source.addEventListener('message', (e) => {
        if (e.data !== '[DONE]') {
          const payload = JSON.parse(e.data);
          const text = payload.choices[0].delta?.content;

          if (payload.choices[0].finish_reason === 'stop') {
            setIsCompleted(true);
            sourceRef.source.close();
            setAnswer('');

            return;
          }
          if (text && text !== '\n') {
            answerRef.current += text;
            setAnswer(answerRef.current);

            if (callback) {
              callback({ answer: answerRef.current });
            }
          }
        } else {
          if (callbackOnFinish) {
            callbackOnFinish({ answer: answerRef.current });
          }
          setIsCompleted(true);
          sourceRef.source.close();
        }
      });

      sourceRef.source.addEventListener('readystatechange', (e) => {
        if (e.readyState >= 2) {
          setIsAnswering(false);
        }
      });

      sourceRef.source.stream();
    } else {
      console.error('Please insert a prompt!');
    }
  };

  const handleStartAnswering = ({ chainName, keepChain }) => {
    if (chainName) {
      return handleStartAnsweringTemplates({ chainName, keepChain });
    }

    return handleStartAnsweringChat({ chainName });
  };

  const handleStopAnswering = () => {
    sourceRef.source?.close();
    setIsCompleted(true);
  };

  return {
    handleStartAnswering,
    handleStopAnswering,
    isAnswering,
    isCompleted,
    answer
  };
};

export default useChatBot;
