import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import LandingPage from './components/LandingPage';
import Question from './components/Question';
import Solution from './components/Solution';
import Login from './components/LoginPage/Login';
import History from './components/History/History';

import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css'; // Import the CSS file where you defined the animation.

const App = observer(() => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/home" element={<LandingPage />} />
    <Route path="/categories/:categoryName" element={<Question />} />
    <Route path="/categories/:categoryName/questions/:questionId" element={<Question />} />
    <Route path="/results" element={<Solution />} />
    <Route path="/results/:tab" element={<Solution />} />
    <Route path="/history" element={<History />} />
  </Routes>
));

export default App;
