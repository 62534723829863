/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { observer } from 'mobx-react-lite';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from '../LoginPage/Login';
import logo from './logo.png';
import authStore from '../../stores/authStore';
import categoryStore from '../../stores/categoryStore';
import logoAnimation from '../Question/logoAnimation.gif';

import './PublicLayout.css';
import Loader from '../Shared/Loader';

const PublicLayout = observer(({ children }) => {
  const { loading, error, fetchCategories } = categoryStore;
  const { logout } = useAuth0();

  const [username, setUsername] = useState('');

  // useEffect(() => {
  //   // Get the username from local storage using the key you want
  //   const storedUsername = localStorage.getItem('UserName'); // Replace 'yourKeyName' with the actual key name

  //   // Check if the username is present in local storage
  //   if (storedUsername) {
  //     // Set the username in the state
  //     setUsername(storedUsername);
  //   }
  // }, []);
  useEffect(() => {
    // Get the "currentUser" data from local storage
    const storedCurrentUser = localStorage.getItem('UserName');

    // If "currentUser" is available, set it in the state
    if (storedCurrentUser) {
      setUsername(storedCurrentUser);
    } else {
      // If "currentUser" is not available, fall back to "UserName"
      const storedUserName = localStorage.getItem('currentUser');
      if (storedUserName) {
        setUsername(storedUserName);
      }
    }
  }, []);

  useEffect(() => {
    if (authStore.isAuthenticated()) {
      fetchCategories();
    }
  }, [fetchCategories, authStore]);

  if (!authStore.isAuthenticated()) {
    return <Login />;
  }

  const user = authStore.user();

  const renderLogo = () => (
    <>
      <img className="logo-icon" src={logo} alt="Your Logo" />
      <div className="public-layout-logo-text">
        <div className="public-layout-logo-prefix">
          <b style={{ fontWeight: '600' }}>Cirrus</b>Insights
        </div>
        <div className="public-layout-logo-suffix">Now AI</div>
      </div>
    </>
  );

  const handleLogout = () => {
    authStore.logout();

    return logout({ logoutParams: { returnTo: window.location.origin } });
  };

  if (error) return <p>Error: {error}</p>;

  return (
    <>
      <div className={`gradient-background ${loading ? 'blue-background' : ''}`}></div>
      <div className="svg-background"></div>
      <div className="public-layout">
        <div className="public-layout-navigation">
          <Link to="/home" className="public-layout-logo">
            {renderLogo()} {/* Use the renderLogo function */}
          </Link>

          <Link className="public-layout-navigation-link" to="/home">
            Home
          </Link>

          <Link className="public-layout-navigation-link" to="/results/lean-business-canvas">
            Solutions
          </Link>

          <Link className="public-layout-navigation-link" to="/history">
            History
          </Link>

          <div>
            <Link>{username}</Link>
          </div>

          <div>
            <button className="logoutbtn" onClick={handleLogout}>
              <div className="logoutDiv">
                <FontAwesomeIcon icon={faSignOutAlt} />
                <div className="logoutText">Logout</div>
              </div>
            </button>
          </div>
        </div>
        {children}
      </div>
    </>
  );
});

export default PublicLayout;
