import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import CategoryList from './CategoryList/CategoryList';

const LandingPage = () => (
  <Container className="landing-page">
    <h3 className="landing-page-title">Select a section to begin</h3>
    <div className="landing-page-categories-list">
      <ListGroup>
        <CategoryList />
      </ListGroup>
    </div>
  </Container>
);

export default LandingPage;
