import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import useChatBotContext from '../../../hooks/useChatBotContext';
import categoryKeyFor from '../../Helpers/CategoryHelper';
import CategoryListItem from './CategoryListItem';
import categoryStore from '../../../stores/categoryStore';

import './CategoryList.css';

const CategoryList = observer(() => {
  const navigate = useNavigate();
  const { categories } = categoryStore;
  const handleRedirectTo = (url) => navigate(url);
  const { contextHistory } = useChatBotContext();
  const [modalOpen1, setModalOpen1] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false); // Added state to control button visibility

  const handleModalOpen1 = () => {
    setModalOpen1(true);
  };

  const isCompleted = (category) =>
    contextHistory()[`${categoryKeyFor(categories[category])}_c_answer`]?.content?.length > 0;

  const handleCloseSession = async () => {
    const token = localStorage.getItem('JWT');
    try {
      // Make the API call to close the session
      const response = await axios.post(`${process.env.REACT_APP_URL}/end_session`, null, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // Check the response status and handle accordingly
      if (response.status === 200) {
        // Session closed successfully, remove the item from local storage
        localStorage.removeItem('chat-gpt-full-context');
        console.log('Session closed successfully');

        // Show a success toast
        toast.success('Data saved successfully', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
          onClose: () => {
            // This callback will be called when the toast message is closed

            // Reload the page here
            window.location.reload();
          }
        });
      } else {
        // Handle any error or failure cases here
        console.error('Failed to close session:', response.statusText);

        // Show an error toast
        toast.error('Something went wrong', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } catch (error) {
      // Handle Axios error
      console.error('An error occurred while closing the session:', error);

      // Show an error toast
      toast.error('Please enter all the data, Please generate solution.', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  const handleModalClose1 = () => {
    setModalOpen1(false);
  };
  const clearData1 = () => {
    handleCloseSession();
    handleModalClose1();
  };

  const dontClearData1 = () => {
    handleModalClose1();
  };

  useEffect(() => {
    // Set a timer to show the "Save Session" button after 3 seconds
    const timer = setTimeout(() => {
      setShowSaveButton(true);
    }, 2000);

    return () => {
      // Clear the timer if the component unmounts
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="category-list">
      <div>
        {Object.keys(categories).map((category) => (
          <CategoryListItem
            key={category}
            onRedirectTo={handleRedirectTo}
            isCompleted={isCompleted(category)}
            category={categories[category]}
          />
        ))}
      </div>
      {showSaveButton && (
        <Button
          variant="primary"
          style={{ opacity: '0.3', marginTop: '70px', marginBottom: '60px', marginLeft: '198px' }}
          onClick={handleModalOpen1}
        >
          Save Session
        </Button>
      )}
      {modalOpen1 && (
        <div className="modal-background-overlay">
          <div className="modalEditUserProfile">
            <div className="modal-content-1UserProfile">
              <p className="para_data">Please confirm to save the data, you have entered.</p>
              <div className="modal-buttonsUserProfile">
                <button className="modal-ok-buttonUserProfile" onClick={clearData1}>
                  Ok
                </button>
                <button className="modal-cancel-buttonUserProfile" onClick={dontClearData1}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
});

export default CategoryList;
