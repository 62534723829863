import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import Html from 'react-pdf-html';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4',
    padding: 16
  },
  header: {
    backgroundColor: '#0073e6',
    color: '#FFFFFF',
    padding: 10,
    textAlign: 'center',
    fontSize: 16,
    marginBottom: 10
  },
  footer: {
    backgroundColor: '#0073e6',
    color: '#FFFFFF',
    padding: 10,
    textAlign: 'center',
    fontSize: 12,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  },
  content: {
    flex: 1,
  }
});

const stylesheet = {
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF', // Set the background color to white
    fontSize: 12,
    marginLeft: 16, // Add left margin
    marginRight: 16, // Add right margin
    marginTop: 6,    // No top margin
    marginBottom: 6, // No bottom margin
  },
  body: {
    margin: 8,
    fontFamily: 'Times-Roman',
    lineHeight: '1.5'
  },
  p1: {
    margin: 0,
    marginTop: '-5px',
    marginBottom: '5px',
    fontSize: '11px'
  },
  h1: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#0073e6',
    marginLeft: '5%',
    marginRight: '5%'
  },
  h2: {
    fontSize: '17px',
    fontWeight: 'bold',
    color: '#0073e6', // Light Blue color
    marginLeft: '5%',
    marginRight: '5%'
  },
  h3: {
    fontSize: '17px',
    fontWeight: 'bold',
    color: '#0073e6', // Light Blue color
    marginLeft: '5%',
    marginRight: '5%'
  },
  h4: {
    fontSize: '17px',
    fontWeight: 'bold',
    color: '#0073e6', // Light Blue color
    marginLeft: '5%',
    marginRight: '5%'
  },
  h5: {
    fontSize: '17px',
    fontWeight: 'bold',
    color: '#0073e6', // Light Blue color
    marginLeft: '5%',
    marginRight: '5%'
  },
  h6: {
    fontSize: '17px',
    fontWeight: 'bold',
    color: '#0073e6', // Light Blue color
    marginLeft: '5%',
    marginRight: '5%'
  },
  strong:{
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#0073e6', // Light Blue color
    marginLeft: '5%',
    marginRight: '5%'
  },
  p: {
    margin: 0,
    fontSize: '15px',
    marginLeft: '5%',
    marginRight: '5%'
  }
};
const PdfDocument = ({ content, withHtml }) => (
  <Document>
    <Page size="A4">
      <View style={styles.header}>
        <Text>CirrusInsightNow.AI</Text>
      </View>
      <View style={styles.content}>
        {withHtml ? <Html stylesheet={stylesheet}>{`<body>${content}</body>`}</Html> : <Text>{content}</Text>}
      </View>
      <View
        style={styles.footer}
        render={({ pageNumber, totalPages }) => (
          <Text>
            {pageNumber} / {totalPages}
          </Text>
        )}
        fixed
      />
    </Page>
  </Document>
);

export default PdfDocument;