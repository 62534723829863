import React, { useMemo, useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { removeHtmlTags } from '../Helpers/HTMLSanitizer';
import useChatBotContext from '../../hooks/useChatBotContext';
import useChatBot from '../../hooks/useChatBot';
import useKeyboardNavigation from '../../hooks/useKeyboardNavigation';
import SlideOver from '../Shared/SlideOver';
import Answer from '../Answer';
import QuestionForm from './QuestionForm';
import QuestionHeader from './QuestionHeader';
import QuestionFooter from './QuestionFooter';
import { messagesForAnswer } from '../Helpers/ChatBotHelper';
import categoryStore from '../../stores/categoryStore';

import './Question.css';
import Loader from '../Shared/Loader';

const Question = observer(() => {
  const { categories: thisCategories } = categoryStore;
  const { loading, error, fetchCategories } = categoryStore;
  const categories = toJS(thisCategories);

  useEffect(() => {
    if (!Object.keys(thisCategories).length) {
      fetchCategories();
    }
  }, [thisCategories, fetchCategories]);

  if (!Object.keys(thisCategories).length) return <Loader />; // We can use this to indicate loading
  if (error) return <p>Error: {error}</p>; // We can use this to indicate loading

  return <QuestionContainer loading={loading} categories={categories} />;
});

const QuestionContainer = ({ loading, categories }) => {
  const [isAnswerChanged, setIsAnswerChanged] = useState(false); // Do not send API call if no changes
  const history = useNavigate();
  const textareaRef = useRef(null);
  const { categoryName, questionId } = useParams();
  const category = categories[categoryName];
  const question = category?.questions.find((item) => item.id.toString() === questionId);
  const questionName = question?.question;
  const [currentAnswer, setCurrentAnswer] = useState(removeHtmlTags(question?.answer));
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const [rowsSize, setRowsSize] = useState(1);
  const questionKeys = useMemo(() => category.questions.map((item) => item.position), [category.questions]);
  const questions = useMemo(() => category.questions.sort((a, b) => a.position - b.position), [category.questions]);

  const updateAnswer = ({ answer }) => {
    setCurrentAnswer(removeHtmlTags(answer));
  };

  const updateChatGPTAnswer = ({ answer }) => {
    setIsAnswerChanged(true);
    setCurrentAnswer(removeHtmlTags(answer));
  };

  const postAnswer = async (answer, callback) => {
    if (isAnswerChanged) {
      await categoryStore.updateAnswer(questionId, answer).then(() => {
        callback();
        setIsAnswerChanged(false);
      });
    } else {
      callback();
    }
  };

  const { writeContext } = useChatBotContext();
  const { handleStartAnswering, handleStopAnswering, isAnswering, answer } = useChatBot({
    callback: updateAnswer,
    callbackOnFinish: updateChatGPTAnswer,
    withAnswerMemo: false,
    messages: messagesForAnswer({ category, question }),
    category
  });

  const recalculateRowsSize = useCallback(() => {
    const element = textareaRef.current;

    const lineHeight = 23;
    const minRows = 1;

    if (currentAnswer.length < 60) {
      return setRowsSize(1);
    }

    if (element) {
      const currentRows = Math.max(minRows, Math.floor(element.scrollHeight / lineHeight));

      setRowsSize(currentRows > 5 ? 5 : currentRows);
    }

    return false;
  }, [currentAnswer, setRowsSize, questionId, textareaRef]);

  useEffect(() => {
    setCurrentAnswer(removeHtmlTags(question?.answer));
  }, [questionId]);

  useEffect(() => {
    recalculateRowsSize();
    writeContext({
      content: `${question.question} ${currentAnswer || 'not sure'}`,
      key: `${category.position}_${categoryName}_${question.position}`
    });
  }, [question, currentAnswer, answer, category, categoryName, questionId, recalculateRowsSize, writeContext]);

  const handleInputChange = (event) => {
    const { value } = event.target;

    recalculateRowsSize();
    setIsAnswerChanged(true);
    updateAnswer({ answer: value });
  };

  const redirectToQuestion = (id) => history(`/categories/${categoryName}/questions/${id}`);
  const handleOpenQuestion = ({ id }) => {
    redirectToQuestion(id || `${questionKeys.length}`);

    setIsSlideOverOpen(false);
  };

  const nextQuestionId = useCallback(() => {
    const currentQuestionIndex = questionKeys.indexOf(question.position);

    if (questionKeys.length - 1 > currentQuestionIndex) {
      return category.questions[currentQuestionIndex + 1].id;
    }

    return 'result';
  }, [question, questionKeys]);

  const prevQuestionId = useCallback(() => {
    const currentQuestionIndex = questionKeys.indexOf(question.position);

    if (currentQuestionIndex > 0) {
      return category.questions[currentQuestionIndex - 1].id;
    }

    return 0;
  }, [question, questionKeys]);

  const handleNextQuestion = () =>
    postAnswer(currentAnswer, () =>
      nextQuestionId() === 'result' ? history(`/results/${categoryName}`) : redirectToQuestion(nextQuestionId())
    );

  const handlePreviousQuestion = () => {
    postAnswer(currentAnswer, () => (prevQuestionId() === 0 ? history('/') : redirectToQuestion(prevQuestionId())));
  };

  useKeyboardNavigation(textareaRef, {
    onLeftArrow: handlePreviousQuestion,
    onRightArrow: handleNextQuestion,
    onEnter: handleNextQuestion
  });

  const handleClearAnswer = () => setCurrentAnswer('');

  const toggleSlideOver = () => {
    setIsSlideOverOpen(!isSlideOverOpen);
  };

  const handlePaginationByQuestionNumber = (questionNumberID) =>
    questionNumberID === 'result' ? history(`/results/${categoryName}`) : redirectToQuestion(questionNumberID);

  return (
    <>
      <SlideOver isOpen={isSlideOverOpen} onClose={toggleSlideOver}>
        <Answer handleOpenQuestion={handleOpenQuestion} category={category} questions={questions} />
      </SlideOver>
      <div className="question-container">
        <div className="question-container-header">
          <div className="question-container-header-top">
            <QuestionHeader
              category={category}
              question={question}
              questionKeys={questionKeys}
              toggleSlideOver={toggleSlideOver}
              questions={category.questions}
              goToQuestion={handlePaginationByQuestionNumber}
              answer={currentAnswer}
            />
            <QuestionForm
              textareaRef={textareaRef}
              rowsSize={rowsSize}
              onInputChange={handleInputChange}
              question={questionName}
              onClearAnswer={handleClearAnswer}
              answer={currentAnswer}
            />
          </div>
        </div>
        <QuestionFooter
          isAnswering={isAnswering}
          onStopAnswering={handleStopAnswering}
          onStartAnswering={handleStartAnswering}
          onPreviousQuestion={handlePreviousQuestion}
          onNextQuestion={handleNextQuestion}
          nextQuestionId={nextQuestionId()}
        />
      </div>
      {loading && <Loader />}
    </>
  );
};

export default Question;
