import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import PublicLayout from './components/Layouts/PublicLayout';
import '@fontsource/inter';
import '@fontsource/inter/400.css';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
const domainAuth0 = process.env.REACT_APP_AUTH0_DOMAIN;
const clientIDAuth0 = process.env.REACT_APP_AUTH0_CLIENT_ID;
root.render(
  <Auth0Provider
    domain={domainAuth0}
    clientId={clientIDAuth0}
    authorizationParams={{
      redirect_uri: window.location.origin
    }}
  >
    <BrowserRouter>
      <PublicLayout>
        <App />
      </PublicLayout>
    </BrowserRouter>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
