import React from 'react';
import Button from 'react-bootstrap/Button';
import 'react-toastify/dist/ReactToastify.css';

import logoAnimation from '../../Question/logoAnimation.gif';

const Loader = () => (
  <div className="loading-container">
    <div className="loading-text">
      <>
        <Button className="question-container-actions-generate1">
          <img src={logoAnimation} className="question-container-actions-logo1" alt="logo animation" />
        </Button>
        {/* <span>Loading...</span> */}
      </>
    </div>
    <div className="loading-spinner"></div>
  </div>
);

export default Loader;
