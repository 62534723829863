/* eslint-disable import/no-extraneous-dependencies */
/* eslint no-return-await: "off" */

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { ArrowUturnLeftIcon, Bars3CenterLeftIcon } from '@heroicons/react/20/solid';
import { Square2StackIcon, ArrowDownTrayIcon, ArrowDownOnSquareStackIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import useChatBotContext from '../../hooks/useChatBotContext';
import categoryKeyFor from '../Helpers/CategoryHelper';
import { copyToClipboard } from '../Helpers/CopyToClipboard';
import { removeDocType, removeHtmlTags } from '../Helpers/HTMLSanitizer';
import PdfDocument from '../PdfDocument';
import logoAnimation from '../Question/logoAnimation.gif';
import SlideOver from '../Shared/SlideOver';
import Answer from '../Answer';
import DialogIcon from '../Shared/Icons/DialogIcon';
import useChatBot from '../../hooks/useChatBot';
import { messagesForSolution } from '../Helpers/ChatBotHelper';
import GherkinSyntax from '../Highlighter/GherkinSyntax';
import categoryStore from '../../stores/categoryStore';
import { chainsNames } from '../../constants/categories';
import { persistData, loadPersistedData } from '../Helpers/PersistData';

import 'react-toastify/dist/ReactToastify.css';
import './Solution.css';
import Loader from '../Shared/Loader';

const Solution = observer(() => {
  const { categories: thisCategories } = categoryStore;
  const { loading, error, fetchCategories } = categoryStore;
  const categories = toJS(thisCategories);

  useEffect(() => {
    if (!Object.keys(thisCategories).length) {
      fetchCategories();
    }
  }, [thisCategories, fetchCategories]);

  if (error) return <p>Error: {error}</p>; // We can use this to indicate loading
  if (!Object.keys(thisCategories).length) return <Loader />; // We can use this to indicate loading

  return <SolutionContainer loading={loading} categories={categories} />;
});

const handleCloseSession = async () => {
  const token = localStorage.getItem('JWT');

  try {
    // Make the API call to close the session
    const response = await axios.post(`${process.env.REACT_APP_URL}/end_session`, null, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    // Check the response status and handle accordingly
    if (response.status === 200) {
      // Session closed successfully, remove the item from local storage
      localStorage.removeItem('chat-gpt-full-context');
      console.log('Session closed successfully');
      // Show a success toast
      toast.success('Data saved successfully', {
        position: toast.POSITION.TOP_RIGHT,
        onClose: () => {
          // This callback will be called when the toast message is closed
          // Reload the page here
          window.location.reload();
        }
      });
    } else {
      // Handle any error or failure cases here
      console.error('Failed to close session:', response.statusText);
      // Show an error toast
      toast.error('Something went wrong', {
        position: toast?.POSITION?.TOP_RIGHT
      });
    }
  } catch (error) {
    // Handle Axios error
    console.error('An error occurred while closing the session:', error);
    // Show an error toast
    toast.error('Something went wrong', {
      position: toast.POSITION.TOP_RIGHT
    });
  }
};

const SolutionContainer = ({ categories, loading }) => {
  const { tab } = useParams();
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const history = useNavigate();
  const { writeContext, contextHistory } = useChatBotContext();
  const tabs = Object.values(categories).map(({ key, name }) => [key, name]);
  const [activeTab, setActiveTab] = useState(tab || tabs[0][0]);
  const category = categories[activeTab];
  const [currentChain, setCurrentChain] = useState(
    loadPersistedData(`${category.key}CurrentChainName`)?.currentChain ||
      (category.chains?.chainName ? category.chains.chainName : null)
  );
  const solution = category.solution || 'No results yet';
  let allAnswers = '';
  tabs.forEach((thisTab) => {
    const content = contextHistory()[`${categoryKeyFor(categories[thisTab[0]])}_c_answer`]?.content;

    if (content?.length > 0) {
      allAnswers = `${allAnswers}\n\n\n\n${content}`;
    }
  });

  useEffect(() => {
    setCurrentChain(
      loadPersistedData(`${category.key}CurrentChainName`)?.currentChain ||
        (category.chains?.chainName ? category.chains.chainName : null)
    );
    // persistData({ currentChain }, `${category.key}CurrentChainName`);
  }, [setCurrentChain, category]);

  const handleSwitchTab = (thisTab) => {
    history(`/results/${thisTab}`);
    // history(0);
    setActiveTab(thisTab);
  };

  const toggleSlideOver = () => {
    setIsSlideOverOpen(!isSlideOverOpen);
  };
  const handleRestart = () => history(`/categories/${activeTab}/questions/1`);
  const handleEditQuestion = ({ id }) => history(`/categories/${activeTab}/questions/${id}`);

  // TODO: move suffix and writeContext to messagesForSolution
  const suffix =
    category.solutionQuestion ||
    `please build the ${category.name} considering my needs, with conclusion, answer with html markup`;

  // We append extra context messages for ChatGPT to generate a better answer
  if (!category.solutionQuestion) {
    writeContext({
      role: 'system',
      content: 'answer using html tags for paragraphs',
      key: `${category.chatKey}_a_chat_task`
    });
  }
  writeContext({ content: suffix, key: `${category.chatKey}_b_suffix` });

  const updateSolution = ({ answer }) => {
    categoryStore.updateSolution(category.id, answer);
  };

  // Answer variable is a what generated by CharGTP
  const { handleStartAnswering, handleStopAnswering, isAnswering, answer } = useChatBot({
    callbackOnFinish: updateSolution,
    messages: messagesForSolution({ category }),
    category,
    withAnswerMemo: true
  });

  const handleAnswering = () => {
    persistData({ currentChain: category?.chains?.chainName }, `${category.key}CurrentChainName`);
    setCurrentChain(category?.chains?.chainName);

    return currentChain ? handleStartAnswering({ chainName: category?.chains?.chainName }) : handleStartAnswering({});
  };

  const handleAnsweringChain = ({ nextChain }) => {
    setCurrentChain(nextChain);
    persistData({ currentChain: nextChain }, `${category.key}CurrentChainName`);
    handleStartAnswering({ chainName: nextChain, keepChain: category.solution });
  };

  // const handleCopyText = async () => await copyToClipboard(removeHtmlTags(answer));
  const handleCopyText = async () => {
    const textToCopy = removeHtmlTags(answer || solution);
    try {
      await navigator.clipboard.writeText(textToCopy);
      toast.success('Text copied to clipboard', {
        position: toast.POSITION.TOP_RIGHT
      });
    } catch (error) {
      console.error('Failed to copy text to clipboard:', error);
      toast.error('Failed to copy text to clipboard', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };
  const answers = [];

  // const handleCopyText = async () => {
  //   const textToCopy = removeHtmlTags(answer || solution);
  //   try {
  //     await navigator.clipboard.writeText(textToCopy);
  //     toast.success('Text copied to clipboard', {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   } catch (error) {
  //     console.error('Failed to copy text to clipboard:', error);
  //     toast.error('Failed to copy text to clipboard', {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }
  // };

  const isResultBlank =
    (answer === 'No results yet' || answer.length === 0) && (solution === 'No results yet' || solution.length === 0);

  return (
    <Container className="category-result">
      <SlideOver isOpen={isSlideOverOpen} onClose={toggleSlideOver}>
        <Answer handleOpenQuestion={handleEditQuestion} category={category} questions={category.questions} />
      </SlideOver>
      <Row className="justify-content-md-center">
        <Nav fill variant="tabs" defaultActiveKey={activeTab} onSelect={handleSwitchTab}>
          {tabs.map((thisTab) => (
            <Nav.Item key={thisTab[0]}>
              <Nav.Link className="category-result-tab-name" eventKey={thisTab[0]}>
                {thisTab[1]}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        <div className="category-result-content">
          <div className="category-result-content-actions">
            <div className="category-result-content-action">
              <ArrowDownOnSquareStackIcon className="category-result-content-action-icon" />
              <PDFDownloadLink
                className="category-result-content-action-button"
                document={<PdfDocument withHtml content={removeDocType(allAnswers)} />}
                fileName={`allResults.pdf`}
              >
                {() => 'Download all as PDF'}
              </PDFDownloadLink>
            </div>
            {!isResultBlank && (
              <div className="category-result-content-action">
                <ArrowDownTrayIcon className="category-result-content-action-icon" />
                <PDFDownloadLink
                  className="category-result-content-action-button category-result-content-action-pdf"
                  document={<PdfDocument withHtml content={removeDocType(answer || solution)} />}
                  fileName={`${activeTab}.pdf`}
                >
                  {() => 'Download as PDF'}
                </PDFDownloadLink>
              </div>
            )}
            <div className="category-result-content-action" onClick={handleRestart}>
              <ArrowUturnLeftIcon className="category-result-content-action-icon" />
              <div className="category-result-content-action-button">Restart</div>
            </div>
            {!isResultBlank && (
              <div className="category-result-content-action" onClick={handleCopyText}>
                <Square2StackIcon className="category-result-content-action-icon" />
                <div className="category-result-content-action-button">Copy to clipboard</div>
              </div>
            )}
            {answers && (
              <div className="category-result-content-action" onClick={toggleSlideOver}>
                <Bars3CenterLeftIcon className="category-result-content-action-icon" />
                <div className="category-result-content-action-button">View questions</div>
              </div>
            )}
            <div
              className="category-result-content-action category-result-content-action-solution"
              onClick={isAnswering ? handleStopAnswering : handleAnswering}
            >
              <div className="">
                <img src={logoAnimation} className="question-container-actions-logo" alt="logo animation" />
                {isAnswering ? 'Generating...' : 'Solution!'}
              </div>
            </div>
          </div>
          <div className="category-result-solution">
            {isResultBlank ? (
              <div dangerouslySetInnerHTML={{ __html: answer || solution }} />
            ) : (
              <div className="category-result-no-results">
                <GherkinSyntax className="category-result-no-results-html" content={answer || solution} />
                {currentChain && chainsNames[currentChain]?.nextChain && !isAnswering && (
                  <button
                    isDisabled={categoryStore.loading}
                    className="category-result-content-action category-result-content-action-solution category-result-content-action-solution-chain"
                    onClick={
                      isAnswering
                        ? handleStopAnswering
                        : () => handleAnsweringChain({ nextChain: chainsNames[currentChain].nextChain })
                    }
                  >
                    <div className="">
                      <img src={logoAnimation} className="question-container-actions-logo" alt="logo animation" />
                      {categoryStore.loading ? 'Saving the progress ...' : `${chainsNames[currentChain].name}!`}
                    </div>
                  </button>
                )}
                <div onClick={handleRestart}>
                  {/* <DialogIcon /> */}
                  {/* Open Questionnaire */}
                </div>
              </div>
            )}
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default Solution;
