import React from 'react';
import ArrowSmallRight from '../../Shared/Icons/ArrowSmallRight';

const CategoryListItem = ({ onRedirectTo, category, isCompleted }) => (
  <div className="category-list-item">
    <br />
    <div>
      <div
        className="category-list-item-action"
        onClick={() => onRedirectTo(`/categories/${category.key}/questions/${category.firstQuestionId}`)}
      >
        <div className="category-row">
          <div className="category-row-name">{category.name}</div>
          <div className="category-row-block" style={{ display: 'flex' }}>
            <div className={`category-row-status ${isCompleted ? 'category-row-status--completed' : ''}`}>
              {isCompleted ? 'Completed' : ''}
            </div>
            <ArrowSmallRight className={`${isCompleted ? 'category-row-arrow--completed' : 'category-row-arrow'}`} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default CategoryListItem;
