import { extendedQuestions } from '../../constants/categories';
import useChatBotContext from '../../hooks/useChatBotContext';
import categoryKeyFor from './CategoryHelper';
import categoryStore from '../../stores/categoryStore';

// Decides how many messages to pass to the ChatGPT context
export const CONTEXT_DEPTH = 5;

const TOKEN_ANSWER_LENGTH = 80;

// Combines messages from contexts and prepare it for ChatGPT
export const messagesForAnswer = ({ category, question }) => {
  const { contextHistory } = useChatBotContext();
  const messages = contextHistory();

  const filteredRecords = [];
  let index = 0;
  let breakAfterIndex;

  Object.keys(messages)
    .sort()
    // eslint-disable-next-line consistent-return
    .forEach((messageKey) => {
      index += 1;

      if (messageKey === `${category.position}_${category.key}_${question.position}`) {
        breakAfterIndex = index;

        return false;
      }
      if (!breakAfterIndex && messages[messageKey].content.length > 0) {
        filteredRecords.push(messages[messageKey]);
      }
    });

  // filteredRecords = filteredRecords.filter((item) => item.role !== 'assistant');
  filteredRecords.push({
    role: 'system',
    content: 'answer without introduction'
  });
  filteredRecords.push({
    role: 'user',
    content: `considering the previous context help me to answer this question${
      extendedQuestions[question.question] ? '' : ', keep it up to 40 words'
    }: ${extendedQuestions[question.question] || question.question}`
  });

  return filteredRecords.slice(-CONTEXT_DEPTH);
};

export const messagesForSolution = ({ category }) => {
  const { categories } = categoryStore;
  const { contextHistory } = useChatBotContext();
  const filteredRecords = [];
  const messages = contextHistory();
  const connectedCategory = categories[category.connectedContext]; // TODO: refactor this, we do not need to use it like that

  Object.keys(messages)
    .sort()
    .forEach((messageKey) => {
      if (
        messages[messageKey]?.content?.length > 0 &&
        (messageKey.startsWith(category.chatKey) ||
          messageKey.startsWith('0_') ||
          (connectedCategory && messageKey.startsWith(categoryKeyFor(connectedCategory))))
      ) {
        messages[messageKey].content =
          messages.length > 3
            ? messages[messageKey].content.slice(0, TOKEN_ANSWER_LENGTH)
            : messages[messageKey].content;
        filteredRecords.push(messages[messageKey]);
      }
    });
  // messages.length > 3 &&
  // if (filteredRecords[filteredRecords.length - 1]?.role === 'assistant') {
  //   filteredRecords.pop();
  // filteredRecords.push({
  //   role: 'user',
  //   content: 'please provide better answer'
  // });
  // }

  return filteredRecords;
};
