/*
  These helpers provide an ability to Save and Get state for the specific key
*/

export const STORAGE_SIZE_KEY = 'storageSize';

// Update if state structure was changed
export const RELEASE_VERSION_KEY = '1.2.0';
export const RECOMMENDED_STORE_LIMIT_IN_KB = 2000;

const RECOMMENDED_TTL_DAYS_IN_MS = 604800000;

const jsonParse = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
};

export const defaultKey = (excludeSearchParams = []) => {
  const url = new URL(window.location.pathname + window.location.search, window.location.origin);

  excludeSearchParams.forEach((param) => {
    url.searchParams.delete(param);
  });

  return `${url.pathname}${url.search}`;
};

export const calculateStorageSize = (str, factor = 1) => {
  const oldSize = JSON.parse(window.localStorage.getItem(STORAGE_SIZE_KEY));
  const newSize = (str.length * 16) / (8 * 1024);

  window.localStorage.setItem(STORAGE_SIZE_KEY, JSON.stringify(oldSize + newSize * factor));
};

export const purgePersistedData = (key = defaultKey()) => {
  const item = window.localStorage.getItem(key);

  if (item) {
    calculateStorageSize(item, -1);
  }

  window.localStorage.removeItem(key);
  window.localStorage.removeItem('chat-gpt-full-context');

  return null;
};

export const cleanUpOldKeys = () => {
  const expireTime = new Date().getTime() - RECOMMENDED_TTL_DAYS_IN_MS;
  const totalSize = JSON.parse(window.localStorage.getItem(STORAGE_SIZE_KEY));

  try {
    if (totalSize < RECOMMENDED_STORE_LIMIT_IN_KB) {
      return;
    }

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in window.localStorage) {
      const item = jsonParse(window.localStorage.getItem(key));

      if (!item) {
        // eslint-disable-next-line no-continue
        continue;
      }

      const { touchedAt, ttl } = item;
      const expiresAt = ttl ? new Date().getTime() - ttl : expireTime;

      if (touchedAt && expiresAt > touchedAt) {
        purgePersistedData(key);
      }
    }
  } catch (errors) {
    // window.localStorage.clear();
  }
};

export const invalidateReleaseVersion = () => {
  if (RELEASE_VERSION_KEY !== window.localStorage.getItem('RELEASE_VERSION_KEY')) {
    // window.localStorage.clear();
    window.localStorage.setItem('RELEASE_VERSION_KEY', RELEASE_VERSION_KEY);
  }
};

const verifyLocalStorageSupport = () => {
  if (typeof Storage === 'undefined') {
    throw new Error();
  }

  invalidateReleaseVersion();
};

const objectToStorage = (object) => {
  const result = { ...object };

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in result) {
    const value = result[key];

    if (key === 'errors') {
      result[key] = [];
    } else if (typeof value === 'object' && value !== null) {
      result[key] = objectToStorage(value);
    } else if (Array.isArray(value)) {
      result[key] = value.map((item) => objectToStorage(item));
    }
  }

  return result;
};

const setItem = (key, object, { ttl } = {}) => {
  try {
    verifyLocalStorageSupport();

    const normalizedObject = objectToStorage(object);
    const isWithTouchAt =
      Object.keys(normalizedObject).length === 2 && 'value' in normalizedObject && 'touchedAt' in normalizedObject;
    const item = JSON.stringify(
      isWithTouchAt ? normalizedObject : { value: normalizedObject, touchedAt: new Date().getTime(), ttl }
    );

    calculateStorageSize(item);
    cleanUpOldKeys();

    return window.localStorage.setItem(key, item);
  } catch (e) {
    return purgePersistedData(key);
  }
};

const getItem = (key) => {
  try {
    verifyLocalStorageSupport();

    const storedItem = window.localStorage.getItem(key);

    if (storedItem) {
      const parsedItem = jsonParse(storedItem);

      return parsedItem?.value;
    }

    return null;
  } catch (error) {
    console.error(error);

    return purgePersistedData(key);
  }
};

export const loadPersistedData = (key = defaultKey()) => getItem(key);

export const persistData = (object, key = defaultKey(), options = {}) => setItem(key, object, options);

export const doesLocalStorageSupports = () => {
  try {
    verifyLocalStorageSupport();

    return true;
  } catch (e) {
    return false;
  }
};

export const cleanPersistedData = () => {
  if (doesLocalStorageSupports()) {
    // window.localStorage.clear();
  }
};
